import request from "@/utils/request";

export function getPhotos(type='photos',  page = 1, searchTerm='',year = 0,month = 0,count=36) {
  return request(
    {
      url: searchTerm.length ? `getAllImages/${year}/${month}/${count}/${type}/${searchTerm}?page=${page}` : `getAllImages/${year}/${month}/${count}/${type}?page=${page}`,
      method: "get"
    }
  )
}

export function searchPhoto(data) {
  return request(
    {
      url: "searchImages",
      method: "post",
      data
    }
  )
}


export function getFileById(id){
  return request(
    {
      url: `getFileById/${id}`,
      method: 'get'
    }
  )
}


export function updateFile(id,data) {
  return request(
    {
      url: `/file/update/${id}`,
      method: 'put',
      data
    }
  )
}
