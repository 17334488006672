<template>
  <main class="bg-light wrapper">
    <div class="container text-center">

        <div class="d-flex copy-box">
          <div>{{STORAGE_URL + file.url}}</div>
          <el-button type="warning" title="نسخ الرابط" @click="copyURL">
            <span class="visually-hidden">copy url</span>
            <i class="far fa-copy"></i>
          </el-button>
        </div>

      <div class="preview-image mx-auto p-3 card border bg-light border-bottom-0 rounded-0 rounded-top">
        <video v-if="file.type === 'video'" class="preview-image  w-100" controls>
          <source :src="STORAGE_URL + file.url" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <audio class="mx-auto" controls v-else-if="file.url.indexOf('.mp3') !== -1">
          <source :src="STORAGE_URL + file.url" type="audio/mpeg">
          Your browser does not support the audio tag.
        </audio>
        <img v-else  :src="STORAGE_URL + file.url" class="preview-image  w-100"  alt="">
      </div>


        <div class="card rounded-bottom rounded-0 text-end p-2 mx-auto preview-image">

          <div class="info text-secondary gap-2 flex-wrap d-flex align-items-baseline">
            <h6 class="text-">
              اسم
              {{ file.type !== 'video' ? 'الصورة' : 'الملف'}}:
            </h6>
            <span>{{file.name}}</span>

          </div>

          <div class="info mt-2 text-secondary gap-2 flex-wrap d-flex align-items-baseline">
          <h6 class="text-">
            تاريخ الرفع:
          </h6>
          <span>{{file.created_at.split('T')[0]}}</span>

          </div>

          <hr>

          <div class="form-group mb-1 d-flex">
            <input type="text" v-model="newName" placeholder="اسم الصورة" class="form-control edit-input">
            <button class="el-button btn-save-draft" @click="handleUpdate" type="button">تعديل</button>
          </div>
        </div>

    </div>
  </main>
</template>

<script setup>
import {useRoute} from 'vue-router';
import {computed, onMounted, ref} from "vue";
import {getFileById, updateFile} from "../services/uploadCenter";
import Loader from "../components/Loader";
import {useToast} from 'vue-toastification'

const emits = defineEmits(['updateFileName'])
const props = defineProps(
    {
      file: {
        default: () => {},
        required: true
      }
    }
)
const toast = useToast()
const route = useRoute()
const newName = ref('')


const STORAGE_URL = computed(()=>  process.env.VUE_APP_IMAGE_REMOTE_URL)
const fileType = computed(() => {
  switch (props.file.type){
    case 'photo':
      return 'صورة'
      case 'video':
        return'فيديو'
      default:
        return 'ملف'
  }
})

const copyURL = () => {
  try
  {
    navigator.clipboard.writeText(STORAGE_URL.value + props.file.url);
  }catch (e)
  {
    let tmp   = document.createElement('textarea');
    tmp.value = STORAGE_URL.value + props.file.url;
    tmp.setAttribute('readonly', '');
    tmp.style.position = 'absolute';
    tmp.style.left     = '-9999px';
    document.body.appendChild(tmp);
    tmp.select();
    tmp.focus();
    document.execCommand('copy');
    document.body.removeChild(tmp);
  }
  toast.success('تم النسخ بنجاح')

}

const handleUpdate = () => {
  emits('updateFileName',newName.value)
  setTimeout(()=>newName.value = '',500)
}

</script>

<style scoped lang="scss">
.wrapper {
  padding: 1.5rem;


  .preview-image {
    max-height: 330px;
    //width: 520px;
    img {
      height: 300px;

      @media (max-width: 768px) {
        height: auto;
      }
    }

    object-fit: contain;
  }

  h6 {
    color: #252525;
    font-weight: 600;
  }

  .edit-input {
    box-shadow: none;
    height: 35px;
  }

  .btn-save-draft {
    margin-right: -8px;
    &:hover {
      background-color: #5448bf;
    }
  }
  .copy-box {
    margin-bottom: 1.5em;
    justify-content: center;

    div {
      padding: 5px 8px;
      direction: ltr;
      word-break: keep-all;
      border-radius: 0 4px 4px 0;
      max-width: 600px;
      overflow-x: auto;
      overflow-y: hidden;
      font-size: 14px;
      vertical-align: middle;
      margin-bottom: 0;
      border: 1px solid #e6dfd5;
      border-left: 0;
    }

    button {
      padding: 0 10px;
      font-size: 1.25em;
      margin-right: -5px;
      height: unset;
    }
  }
}
</style>
