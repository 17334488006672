<template>
  <div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="مركز رفع الملفات" name="nav-upload-tab">
        <div class="add-images tab-content-wrapper">
          <div class="files-info my-3">
            <h5>عدد الملفات :
              <span>{{ photosToUpload.length || 0 }}</span>
            </h5>
            <div class="upload-btns gap-2 d-flex">
              <button class="btn btn-success py-1 px-2" type="button"
                      :disabled="photosToUpload.length === 0 || uploading" @click="upload_all">رفع الكل
              </button>
              <button class="btn btn-warning py-1 px-2" type="button"
                      :disabled="!uploading" @click="cancel_upload">
                إلغاء الرفع
              </button>
              <button class="btn btn-danger py-1 px-2" type="button"
                      :disabled="photosToUpload.length === 0" @click="delete_all">حذف الكل
              </button>
            </div>
          </div>
          <el-upload
              class="upload-demo upload-wrapper"
              drag
              ref="upload"
              accept="image/*,video/*,audio/*,.pdf,.doc,.docx"
              :action="uploadEndPoint"
              :headers="headers"
              :auto-upload="false"
              :on-remove="handleRemove"
              :on-success="handleUpload"
              list-type="picture"
              :on-change="handleSelected"
              :multiple="!isSingleUpload"
              :limit="isSingleUpload ? 1 : 0"
              :before-upload="beforeUpload"
              :on-error="handleUploadError"
          >
            <el-icon class="el-icon--upload">
              <upload-filled/>
            </el-icon>
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em>
            </div>
            <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template>

            <!--            <el-button class="ml-3" type="success" @click="submitUpload"-->
            <!--            >upload to server</el-button>-->
          </el-upload>
        </div>
      </el-tab-pane>
      <div class="d-flex justify-content-between" v-if="activeTab !== 'nav-upload-tab'">
        <form class="searchForm uploadCenterForm mt-1" @submit.prevent="handleSearch">
          <div class="form-group position-relative">
            <input
                type="text"
                name="name"
                placeholder="ابحث هنا ..."
                id="image-name"
                v-model="imageSearch.name"
                class="form-control rounded-1"
                style="padding-inline-end:2rem"
            />
            <button class="btn position-absolute reset-btn start-0" type="reset"
                    v-show="imageSearch.name"
                    @click="resetFiles">
              <span class="visually-hidden">Reset</span>
              <i class="fa fa-times text-secondary"></i>
            </button>
          </div>

          <!--            <div class="form-group">-->
          <!--              <label for="upload-date" class="mb-1">التاريخ</label>-->
          <!--              <input-->
          <!--                  type="date"-->
          <!--                  name="upload-date"-->
          <!--                  id="upload-date"-->
          <!--                  v-model="imageSearch.date"-->
          <!--                  class="form-control rounded-1"-->
          <!--              />-->
          <!--            </div>-->

          <button class="btn btn-search px-3" :disabled="!imageSearch.name">بحث
          </button>
        </form>

        <button v-if="$route.name !== 'uploadCenter'" class="align-self-baseline btn btn-success px-4"
                @click="handleAddPhotos" type="button"
                :disabled="photosList.length === 0">
          إضافة
        </button>
      </div>

      <el-tab-pane label="الصور" name="nav-image-tab">


        <div class="media-section mb-3 tab-content-wrapper preview-images">
          <template v-if="!loading">
            <div
                v-if="photos.length"
               class="card-image el-upload-list--picture position-relative"
               v-for="photo in photos"
               :key="photo.id"
               @click="handleSelectPhoto(photo)"
               :class="{ isSelected: checkIfInList(photo.id) }"
          >
            <label class="el-upload-list__item-status-label" :class="{'d-inline-block' : checkIfInList(photo.id)}">
              <i class="el-icon el-icon--upload-success el-icon--check">
                <svg
                    class="icon"
                    width="200"
                    height="200"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      fill="#FFF"
                      d="M406.656 706.944L195.84 496.256a32 32 0 10-45.248 45.248l256 256 512-512a32 32 0 00-45.248-45.248L406.592 706.944z"
                  ></path>
                </svg>
              </i>
            </label>
            <img :src="STORAGE_URL + '150x150/' + photo.url" :alt="photo.name"/>
            <template v-if="$route.name === 'uploadCenter'">
              <div class="showInHover">
                <button  @click.stop="handlePreview(photo.id)" type="button" class="btn w-100 h-100">
                  <span class="bg-light btn p-1 rounded-pill">
                    <i class="las text-dark la-link"/>
                  </span>
                </button>
              </div>
            </template>

          </div>
            <div class="mt-4 el-card w-100 min-vh-100 p-3" v-else>لا يوجد صور لعرضها</div>
          </template>


          <div v-else class="w-100 el-card">
            <Loader/>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="الفيديو" name="nav-videos-tab">
        <div class="media-section tab-content-wrapper preview-images">
          <template v-if="!loading">

          <div v-for="file in videos" v-if="videos.length" :key="file.id" class="bg-secondary el-upload-list--picture card-image preview-file"
               @click="handleSelectPhoto(file)" :class="{ isSelected: checkIfInList(file.id) }">
            <label class="el-upload-list__item-status-label" :class="{'d-inline-block' : checkIfInList(file.id)}">
              <i class="el-icon el-icon--upload-success el-icon--check">
                <svg
                    class="icon"
                    width="200"
                    height="200"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      fill="#FFF"
                      d="M406.656 706.944L195.84 496.256a32 32 0 10-45.248 45.248l256 256 512-512a32 32 0 00-45.248-45.248L406.592 706.944z"
                  ></path>
                </svg>
              </i>
            </label>
            <img src="/media/logo.png" :alt="file.name">
            <template v-if="$route.name === 'uploadCenter'">
              <div class="showInHover">
                <button  @click.stop="handlePreview(file.id)" type="button" class="btn w-100 h-100">
                  <span class="bg-light btn p-1 rounded-pill">
                    <i class="las text-dark la-link"/>
                  </span>
                </button>
              </div>
            </template>
          </div>
            <div class="mt-4 el-card w-100 min-vh-100 p-3" v-else>لا يوجد صور لعرضها</div>

          </template>
          <div v-else class="w-100 el-card">
            <Loader/>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="ملفات أخرى" name="nav-files-tab">
        <div class="media-section tab-content-wrapper preview-images">
          <template v-if="!loading">
            <div v-for="file in files" :key="file.id" v-if="files.length" class="bg-secondary  el-upload-list--picture card-image preview-file"
                 @click="handleSelectPhoto(file)" :class="{ isSelected: checkIfInList(file.id) }">
              <img v-if="file.url.indexOf('.webp')" :src="STORAGE_URL + '150x150/' + file.url" :alt="file.name" class="w-100" style="height: 100% !important;"/>
              <template v-else>
                <label class="el-upload-list__item-status-label" :class="{'d-inline-block' : checkIfInList(file.id)}">
                  <i class="el-icon el-icon--upload-success el-icon--check">
                    <svg
                        class="icon"
                        width="200"
                        height="200"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          fill="#FFF"
                          d="M406.656 706.944L195.84 496.256a32 32 0 10-45.248 45.248l256 256 512-512a32 32 0 00-45.248-45.248L406.592 706.944z"
                      ></path>
                    </svg>
                  </i>
                </label>
                <img src="/media/logo.png" :alt="file.name">
              </template>
              <template v-if="$route.name === 'uploadCenter'">
                <div class="showInHover">
                  <button  @click.stop="handlePreview(file.id)" type="button" class="btn w-100 h-100">
                  <span class="bg-light btn p-1 rounded-pill">
                      <i class="las text-dark la-link"/>
                    </span>
                  </button>
                </div>
              </template>
              <template v-else>
                <div class="showInHover files" v-if="file.url.indexOf('.mp3') === -1">
                  <button  @click.stop="handlePreview(file.id)" type="button" class="btn w-100 h-100">
                  <span class="bg-light btn p-1 rounded-pill">
                      <i class="las text-dark la-link"/>
                    </span>
                  </button>
                </div>
              </template>

            </div>
          </template>
          <div v-else class="w-100 el-card">
            <Loader/>
          </div>

        </div>
      </el-tab-pane>

    </el-tabs>

    <div class="d-flex justify-content-center mt-5">
      <pagination v-if="[fetchType].length && activeTab !== 'nav-upload-tab'" v-model="paginationSetup.page" :records="paginationSetup.total"
                  :per-page="paginationSetup.per_page" :options="paginationOptions"
                  @paginate="customPaginate($event)"/>
    </div>

    <div   class="preview-modal">
      <el-dialog
          v-model="dialogVisible"
          title="معاينة الملف"
          top="3vh"
      >
        <preview @updateFileName="updateFileName" :file="itemToPreview"/>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {UploadFilled} from "@element-plus/icons-vue";
import {getPhotos} from "@/services/uploadCenter";
import axios from "axios";
import Loader from "../Loader";
import Preview from "../../views/preview";
import {updateFile} from "../../services/uploadCenter";
import {useToast} from "vue-toastification";

export default {
  name      : "uploadCenter",
  components: {Preview, Loader, UploadFilled},
  props     : ["isSingleUpload"],
  data()
  {
    return {
      toast: useToast(),
      activeTab        : "nav-upload-tab",
      isRefetch : false,
      dialogVisible : false,
      itemToPreview: {},
      photos           : [],
      files            : [],
      videos           : [],
      photosToUpload   : [],
      photosList       : [],
      paginationSetup  : {
        page    : 1,
        total   : 1,
        per_page: 36
      },
      paginationOptions: {
        chunk    : 5,
        theme    : "bootstrap4",
        hideCount: true
      },
      fetchType        : '',
      uploading        : false,
      loading          : true,
      hover            : false,
      imageSearch      : {
        name: "",
        date: "",
      },
    };
  },
  computed: {
    uploadEndPoint: function () {
      return process.env.VUE_APP_REMOTE_URL + "/uploadFiles";
    },
    headers       : function () {
      const token = localStorage.getItem("access_token") || '';
      return {'Authorization': `Bearer ${JSON.parse(token)}`}
    },
    STORAGE_URL   : function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL;
    },
  },
  watch   : {
    activeTab: function (newVal) {
      this.paginationSetup.page  = 1;
      this.paginationSetup.total = 1;
      if (newVal === 'nav-image-tab')
      {
        this.fetchType = 'photos'
        this.fetchPrevUploadedPhotos('photos');
      } else if (newVal === 'nav-videos-tab')
      {
        this.fetchType = 'videos'
        this.fetchPrevUploadedPhotos('videos');
      } else if (newVal === 'nav-files-tab')
      {
        this.fetchType = 'files'
        this.fetchPrevUploadedPhotos('files');
      }
    }
  },
  methods : {
    handlePreview(id) {
      let file = this[this.fetchType].find(item=>item.id === id)
      if(file) {
        this.itemToPreview = file
        this.dialogVisible = true
      }
    },
    async  updateFileName(val)  {
      try
      {
        const result = await updateFile(this.itemToPreview.id,{name: val})
        this.itemToPreview.name = val
        this.toast.success('تم التعديل بنجاح')
        this.dialogVisible = false
      }catch (e){
        this.toast.error('الرجاء التأكد من البيانات المدخلة, والمحاولة مرة أخرى')
      }
    },
    handleSelected(file)
    {
      if(file.status !== 'fail')
      {
        this.photosToUpload.push(file)
      }
    },
    handleUploadError(error, uploadFile, uploadFiles)
    {
      this.uploading = false
      this.toast.error('خطأ في الرفع, الرجاء المحاولة مرة أخرى')
      console.log('error ', error)
    },
    delete_all()
    {
      this.$refs.upload?.clearFiles()
      this.photosToUpload = []
    },
    upload_all()
    {
      try
      {
        this.uploading = true
        this.$refs.upload.submit()
      }catch (e)
      {
        this.uploading = false
        console.log(e)

      }
    },
    cancel_upload()
    {
      this.$refs.upload.abort();
    },
    beforeUpload(rawFile)
    {
      //if (rawFile.type === 'image/svg+xml')
      //{
      //  const data = new FormData();
      //  data.append('file', rawFile)
      //  axios.post(process.env.VUE_APP_REMOTE_URL + "/uploadFiles", data)
      //      .then(res => console.log(res))
      //      .catch(e => console.log(e))
      //
      //  return false
      //}

      return true
    },
    handleUpload(response, file, fileList)
    {
      if (this.isSingleUpload)
      {
        if (file.status === 'success')
        {
          let path = response.split('uploads')
          this.handleSelectPhoto({url: `uploads${path[1]}`})
        }
      }else {
          this.activeTab = 'nav-image-tab'
      }
      this.delete_all()
      this.uploading = false;
    },
    handleRemove(file, fileList)
    {
      let idx = this.photosToUpload.findIndex(
          (photo) => photo.uid === file.uid
      );
      this.photosToUpload.splice(idx, 1);
    },
    handleProgress()
    {
      this.uploading = true;
    },
    resetFiles()
    {
      this.imageSearch.name = ''
      if (this.isRefetch)
      {
        this.fetchPrevUploadedPhotos(this.fetchType)
        this.isRefetch = false
      }
    },
    fetchPrevUploadedPhotos(type)
    {
      //this.imageSearch.name = ''
      this.loading          = true
      getPhotos(type, this.paginationSetup.page).then((res) => {
        switch (type)
        {
          case 'photos':
            this.photos = res.data.data;
            break;
          case 'videos':
            this.videos = res.data.data;
            break
          case 'files':
            this.files = res.data.data;
            break

        }

        this.paginationSetup.page  = res.data.current_page;
        this.paginationSetup.total = res.data.total;
        this.loading               = false
      }).catch(() => this.loading = false)
    },
    customPaginate(e)
    {
      e.preventDefault();
      this.fetchPrevUploadedPhotos(this.fetchType)
    },

    handleSearch()
    {
      this.loading              = true
      this.isRefetch = true
      this.paginationSetup.page = 1
      getPhotos(this.fetchType, this.paginationSetup.page, this.imageSearch.name).then((res) => {
        switch (this.fetchType)
        {
          case 'photos':
            this.photos = res.data.data;
            //this.imageSearch.name = ''
            break;
          case 'videos':
            this.videos = res.data.data
            break;
          case 'files':
            this.files = res.data.data;
            break
        }
        this.paginationSetup.page  = res.data.current_page;
        this.paginationSetup.total = res.data.total;
        this.loading               = false
      }).catch(() => this.loading = false)
    },
    handleSelectPhoto(photo)
    {
      let idx = this.photosList.findIndex(img => img.id === photo.id);
      idx === -1 ? this.photosList.push(photo) : this.photosList.splice(idx, 1);

      this.isSingleUpload ? this.$emit("save", this.photosList[0]) : "";

    },
    checkIfInList(id)
    {
      let idx = this.photosList.findIndex(img => img.id === id);
      return idx !== -1
    },

    handleAddPhotos()
    {
      this.isSingleUpload ? this.$emit('save', this.photosList[0]) : this.$emit('save', this.photosList);
    }

  },
};
</script>

<style lang="scss">
.preview-modal .el-dialog {
  width: 55%;

  @media (max-width: 768px) {
    width: 80%;
  }
}
.preview-modal .el-dialog__body {
  padding: 20px;
}
.el-dialog .el-dialog__header {
  border-bottom: 1px solid #dee2e6;
}

.uploadCenterForm.searchForm .form-control {
  width: unset;
}

[dir=rtl] .el-dialog__headerbtn {
  right: unset;
  left: 20px;
}

.preview-file.card-image {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  img {
    width: 60px;
    height: 60px !important;
    object-fit: contain;
  }
}

.nav-tabs {
  padding-inline-start: 10px;
  border-bottom: 0;
  background-color: #eceef0;


  .nav-link {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    color: #181c32;
    border-width: 0 0 2px 0;
    border-radius: 0;
  }

  .nav-link.active {
    //background-color: transparent;
    border-bottom: 2px solid #3f51b5;
    color: #fff;
    background-color: #409eff;
  }
}

.upload-wrapper {
  margin-top: 1.5rem;

  .el-upload,
  .el-upload-dragger {
    width: 100%;
    height: 230px;
  }

  .el-upload-list--picture {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .el-upload-list__item {
      max-width: 300px;
    }
  }
}

.media-section {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  .card-image {
    overflow: hidden;
    max-width: 150px;
    border: 3px solid #eee;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    transition: all 200ms ease-in-out;

    .showInHover {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .16);

      &.files {
        width: unset;
        left:15px;
        top:15px;
        height: unset;
        right:15px;
        text-align: center;
      }
      a.btn {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        background-color: transparent;
        color: #fff;
      }

      i {
        color: #fff;
        font-size: 1.75em;
      }
    }

    &:hover {
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.26);
      border-color: #bebebe;

      .showInHover {
        display: flex;
      }

    }

    &.isSelected {
      border-color: #13ce66;
    }

    img {
      height: 100%;
    }
  }
}

.searchForm {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;
  flex-wrap: wrap;

  > div {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 0 0.75rem;
  }

  .form-control {
    height: 38px;
  }

  .btn-search {
    background-color: #3f51b5;
    border-color: #3f51b5;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
